<template>
  <el-card v-loading="isLoading" shadow="never">
    <template #header>
      <auth-card-header :heading="$t('page_login.form_title')" />
    </template>
    <el-form
        :rules="formRules"
        size="medium"
        status-icon
        :model="form"
        ref="loginForm"
    >
      <el-form-item prop="email">
        <el-input :placeholder="$t('forms.email')" v-model="form.email" type="email"/>
      </el-form-item>
      <el-form-item prop="password">
        <el-input :placeholder="$t('forms.password')" v-model="form.password" type="password"/>
      </el-form-item>
      <el-form-item>
        <div class="login-rules-info">
          {{ $t('page_login.rules_info') }}
          <a :href="$options.TERM_OF_REGULATION_DOCUMENT">
            {{ $t('page_login.rules_link') }}
          </a>
        </div>
        <v-wide-button size="large" type="primary" @click.native.prevent="handleLogin">
          {{ $t('page_login.btn_submit') }}
        </v-wide-button>
      </el-form-item>
      <v-alert ref="alert" />
      <div class="text-center">
        <el-link @click="$router.push({name: 'register'})" :underline="false" type="primary">
          {{ $t('page_login.register_link') }}
        </el-link>
        <v-divider/>
        <el-link @click="$router.push({name: 'reset-password'})" :underline="false" type="primary">
          {{ $t('page_login.forgot_password_link') }}
        </el-link>
      </div>
    </el-form>
  </el-card>
</template>

<script>
import * as backgroundImage from '../../../assets/images/bg-login.jpg'
import {loginUser} from '../shared/services/auth'
import {mapState, mapActions} from 'vuex'
import VWideButton from '../../components/ui/v-wide-button/VWideButton'
import VDivider from '../../components/ui/v-divider/VDivider'
import VAlert from '../../components/ui/v-alert/VAlert'
import AuthCardHeader from '../shared/components/auth-card-header/AuthCardHeader'
import {TERM_OF_REGULATION_DOCUMENT} from '../../shared/constants/app-documents'

export default {
  name: 'AuthLogin',
  components: {AuthCardHeader, VAlert, VDivider, VWideButton},
  inject: ['changeLayoutImage'],
  TERM_OF_REGULATION_DOCUMENT,
  data() {
    return {
      formRules: {
        email: [
          {required: true, message: this.$t('validators.field_required')},
          {type: 'email', message: this.$t('validators.incorrect_email')}
        ],
        password: {required: true, message: this.$t('validators.field_required')}
      },
      form: {
        email: '',
        password: ''
      },
    }
  },
  computed: {
    ...mapState('loadingState', {
      isLoading: state => state.isLoading
    }),
  },
  created() {
    this.changeLayoutImage(backgroundImage)
  },
  methods: {
    ...mapActions(['authState/setUser']),
    goNext() {
      const {back} = this.$route.params
      if (back) {
        this.$router.back()
        return
      }
      this.$router.push({name: 'home'})
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (!valid) return
        loginUser(this.form)
            .then(async () => {
              await this['authState/setUser']()
              this.goNext()
            })
            .catch((error) => {
              this.$refs.alert.showAlert({
                type: 'error',
                timeout: 5000,
                text: this.$t(`alerts.${error.data.message}`)
              })
            })
      })
    }
  }
}
</script>

<style scoped>
.login-rules-info {
  font-size: 0.8em!important;
  color: #606266;
  line-height: 1.2em;
  margin-bottom: 0.5em;
}
</style>
