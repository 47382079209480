<template>
  <component :is="transitionComponent">
    <div v-show="show" style="margin: 15px 0;">
      <el-alert :closable="false" :type="options.type">
        {{ options.text }}
      </el-alert>
    </div>
  </component>
</template>

<script>
export default {
  name: 'VAlert',
  props: {
    transitionType: {
      type: String,
      default: 'collapse'
    },
  },
  data() {
    return {
      show: false,
      options: {
        type: 'info',
        timeout: 0,
        text: ''
      }
    }
  },
  methods: {
    showAlert(options) {
      this.options = {
        ...this.options,
        ...options
      }
      this.show = true
      if (this.options.timeout) {
        setTimeout(this.hideAlert, this.options.timeout)
      }
    },
    hideAlert() {
      this.show = false
    }
  },
  computed: {
    transitionComponent() {
      return `el-${this.transitionType}-transition`
    }
  }
}
</script>
