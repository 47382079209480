<template>
  <div class="d-flex justify-between align-center">
    <h2 class="heading page-heading">
      {{ heading }}
    </h2>
    <v-button class="button-round" circle icon="close" @click="goToMainPage"/>
  </div>
</template>

<script>
import VButton from '../../../../components/ui/v-button/VButton'
import {MAIN_PAGE_ROUTE_NAME} from '../../../../shared/constants/app-routes-names'

export default {
  name: 'AuthCardHeader',
  components: {VButton},
  props: {
    heading: {
      type: String,
      required: true
    }
  },
  methods: {
    goToMainPage() {
      this.$router.push({
        name: MAIN_PAGE_ROUTE_NAME
      })
    }
  }
}
</script>

<style scoped>
.heading {
  margin-bottom: 0;
}
</style>

