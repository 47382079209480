<template>
  <div class="el-divider"
       :class="vertical ? 'divider--vertical' : 'divider--horizontal'"
  />
</template>

<script>
export default {
  name: 'VDivider',
  props: {
    vertical: {
      type: Boolean,
      default: false
    },
  },
}
</script>

<style lang="scss" scoped>
.divider {
  &--vertical {
    height: 100%;
    width: 1px;
  }
  &--horizontal {
    height: 1px;
    margin: 1em 0;
  }
}
</style>
